import React from 'react'
import styled from 'styled-components'
// import { ReactComponent as Logo } from 'img/logo.svg'
import { ReactComponent as Logo } from 'img/logo-black.svg'
import Navigation from './Navigation'
import MobNavigation from './MobNavigation'

interface Props {}

function Header(props: Props) {
  const {} = props

  return (
    <RootWrapper>
      <Root>
        <LogoWrap>
          <Logo />
        </LogoWrap>
        <Navigation />
      </Root>
    </RootWrapper>
  )
}

export default Header

const RootWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 60px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 60px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 48px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 38px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 109px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding-top: 48px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-top: 41px;
  }
`

const Root = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 100px;
  overflow: hidden;
  padding-right: 100px;
  flex-shrink: 0;
  ${({ theme }) => theme.adaptive.xl} {
    padding-right: 88px;
    padding-left: 88px;
  }

  ${({ theme }) => theme.adaptive.lg} {
    padding-right: 74px;
    padding-left: 74px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-right: 63px;
    padding-left: 63px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-right: 45px;
    padding-left: 45px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding-right: 20px;
    padding-left: 20px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-right: 17px;
    padding-left: 17px;
  }
  &:before {
    content: '';
    position: absolute;
    left: -125px;
    top: 0;
    background: #ddd9d8;
    height: 55px;
    width: 200px;
    transform: skew(-45deg);
    ${({ theme }) => theme.adaptive.xl} {
      height: 44px;
      width: 160px;
      left: -90px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      height: 38px;
      width: 138px;
      left: -70px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      height: 33px;
      width: 120px;
      left: -80px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      display: none;
    }
  }
  &:after {
    content: '';
    position: absolute;
    right: -125px;
    top: 0;
    background: #ddd9d8;
    height: 51px;
    width: 200px;
    transform: skew(-45deg);
    ${({ theme }) => theme.adaptive.xl} {
      height: 44px;
      width: 160px;
      right: -90px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      height: 38px;
      width: 138px;
      right: -80px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      height: 33px;
      width: 120px;
      right: -70px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      height: 59px;
      width: 215px;
      right: -70px;
    }
    ${({ theme }) => theme.adaptive.smm} {
      height: 46px;
      width: 167px;
      right: -120px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      height: 39px;
      width: 142px;
      right: -100px;
    }
  }
`

const LogoWrap = styled.div`
  width: 266px;
  height: 45px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 259px;
    height: 44px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 222px;
    height: 38px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 170px;
    height: 32px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 330px;
    height: 56px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 267px;
    height: 45px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 228px;
    height: 38px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
