import React from 'react'
import styled from 'styled-components'
import titleImg from 'img/main/title.svg'
import titleBgImg from 'img/main/title-bg.svg'
import titleBgImgSm from 'img/main/title-bg-sm.svg'
import titleBgImgSmm from 'img/main/title-bg-smm.svg'
import grayImg from 'img/main/gray-img.svg'
import grayImgSm from 'img/main/gray-img-sm.svg'

interface Props {}

function Main(props: Props) {
  const {} = props

  return (
    <Root>
      <Title>
        <TitleBg />
        <SubTitleWrap>
          <By>by</By>
          <SubTitleBlock>
            <SubTitle>SUNFLOWER</SubTitle>
            <Corp>CORPORATON</Corp>
          </SubTitleBlock>
        </SubTitleWrap>
        <GrayBg />
      </Title>
    </Root>
  )
}

export default Main

const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Title = styled.div`
  width: 970px;
  height: 269px;
  background: url('${titleImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    width: 849px;
    height: 235px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 728px;
    height: 202px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 627px;
    height: 174px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 486px;
    height: 135px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 334px;
    height: 93px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 282px;
    height: 78px;
  }
`

const SubTitleWrap = styled.div`
  position: absolute;
  bottom: -60px;
  right: 0;
  display: flex;
  ${({ theme }) => theme.adaptive.xl} {
    bottom: -58px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    bottom: -50px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    bottom: -43px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    bottom: -48px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    bottom: -34px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    bottom: -34px;
  }
`

const By = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #050909;
  mix-blend-mode: normal;
  margin-right: 10px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 14px;
    margin-right: 9px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 12px;
    margin-right: 8px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 10px;
    margin-right: 7px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 12px;
    margin-right: 8px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 8px;
    margin-right: 6px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 7px;
    margin-right: 5px;
  }
`

const SubTitleBlock = styled.div``

const SubTitle = styled.div`
  font-weight: bold;
  font-size: 29px;
  line-height: 30px;
  letter-spacing: -0.03em;
  color: #050909;
  mix-blend-mode: normal;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 25px;
    line-height: 26px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 22px;
    line-height: 23px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 18px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 22px;
    line-height: 23px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 15px;
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 13px;
    line-height: 13px;
  }
`

const Corp = styled.div`
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #050909;
  mix-blend-mode: normal;
  opacity: 0.75;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 10px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 9px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 10px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 7px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 6px;
  }
`

const TitleBg = styled.div`
  position: absolute;
  right: -37px;
  top: -132px;
  width: 624px;
  height: 603px;
  background: url('${titleBgImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    right: -32px;
    top: -120px;
    width: 546px;
    height: 527px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    right: -27px;
    top: -101px;
    width: 468px;
    height: 452px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    right: -23px;
    top: -88px;
    width: 403px;
    height: 389px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    right: -112px;
    top: -112px;
    width: 403px;
    height: 389px;
    background: url('${titleBgImgSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.smm} {
    right: -4px;
    top: -35px;
    width: 97px;
    height: 157px;
    background: url('${titleBgImgSmm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.xs} {
    right: -11px;
    top: -40px;
  }
`

const GrayBg = styled.div`
  position: absolute;
  right: -155px;
  top: -266px;
  width: 2310px;
  height: 1354px;
  background: url('${grayImg}') center no-repeat;
  background-size: cover;
  z-index: -1;
  ${({ theme }) => theme.adaptive.xl} {
    right: -135px;
    top: -230px;
    width: 2012px;
    height: 1179px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    right: -124px;
    top: -198px;
    width: 1749px;
    height: 1025px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    right: -107px;
    top: -173px;
    width: 1511px;
    height: 885px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    right: -1921px;
    top: -671px;
    width: 3644px;
    height: 2563px;
    transform: rotate(-4deg);
    background: url('${grayImgSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.smm} {
    right: -1275px;
    top: -432px;
    width: 2458px;
    height: 1729px;
    transform: rotate(0deg);
  }
  ${({ theme }) => theme.adaptive.xs} {
    right: -1021px;
    top: -375px;
    width: 2123px;
    height: 1494px;
  }
`
