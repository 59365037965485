interface NavItem {
  link: string
  name: string
  nameSm?: string
}

export const navList: NavItem[] = [
  {
    link: 'https://sunflowercorp.zendesk.com/hc/en-us',
    name: 'About Sunflower',
  },
  {
    link: 'mailto: sigma@sunflowercorp.com',
    name: 'Contact',
  },
]
