import React from 'react'
import styled, { css } from 'styled-components'
// import Game from 'components/Game'
import Header from 'components/Header'
import Socials from 'components/Socials'
import Main from 'components/Main'
import Noise from '../Common/Noise'
import MobNavigation from '../Header/MobNavigation'

interface Props {}

function Index(props: Props) {
  const {} = props

  const onGameEnd = () => {
    console.log('game end')
  }

  return (
    <Root>
      <Noise />
      {/* <button type="button" onClick={() => {}}></button> */}
      <TopLines>
        <Line type="topLeft" />
        <Line type="topMid" />
        <Line type="topRight" />
      </TopLines>
      <Header />
      <GameWrapper>{/* <Game onGameEnd={onGameEnd} /> */}</GameWrapper>
      <Main />
      <MobNavigation />
      <Socials />
      <BottomLines>
        <Line type="bottomRight" />
      </BottomLines>
    </Root>
  )
}

export default Index

const Root = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
`
const GameWrapper = styled.div`
  height: 100%;
  margin: 0 auto;
`

interface LineProps {
  type: string
}

const Line = styled.div<LineProps>`
  position: relative;
  height: 10px;
  background: #030506;
  transform: skew(-45deg);
  ${({ theme }) => theme.adaptive.xl} {
    height: 9px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 8px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 7px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    height: 20px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    height: 9px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 7px;
  }
  ${({ type }) => {
    if (type === 'topLeft') {
      return css`
        left: -10px;
        width: 100px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 62px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 55px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 48px;
        }
        ${({ theme }) => theme.adaptive.sm} {
          left: -30px;
          width: 115px;
        }
        ${({ theme }) => theme.adaptive.smm} {
          left: -10px;
          width: 47px;
        }
        ${({ theme }) => theme.adaptive.xs} {
          width: 42px;
        }
      `
    }
    if (type === 'topMid') {
      return css`
        width: 400px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 400px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 344px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 296px;
        }
        ${({ theme }) => theme.adaptive.sm} {
          display: none;
        }
      `
    }
    if (type === 'topRight') {
      return css`
        right: -10px;
        width: 460px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 421px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 371px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 320px;
        }
        ${({ theme }) => theme.adaptive.sm} {
          right: -30px;
          width: 451px;
        }
        ${({ theme }) => theme.adaptive.smm} {
          right: -10px;
          width: 197px;
        }
        ${({ theme }) => theme.adaptive.xs} {
          width: 167px;
        }
      `
    }
    if (type === 'bottomRight') {
      return css`
        right: -10px;
        width: 150px;
        margin-left: auto;
        ${({ theme }) => theme.adaptive.xl} {
          width: 146px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 126px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 110px;
        }
        ${({ theme }) => theme.adaptive.sm} {
          display: none;
        }
      `
    }
  }}
`

const TopLines = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const BottomLines = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
`
