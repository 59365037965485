import React from 'react'
import styled, { keyframes } from 'styled-components'
import noiseBg from 'img/noise.png'

const Noise: React.FunctionComponent = () => (
  <Root>
    <NoiseBg />
  </Root>
)

export default Noise

const Root = styled.div`
  pointer-events: none;
`

const NoiseAnimation = keyframes`
  0% { transform: translate(0,0) }
  10% { transform: translate(-5%,-5%) }
  20% { transform: translate(-10%,5%) }
  30% { transform: translate(5%,-10%) }
  40% { transform: translate(-5%,15%) }
  50% { transform: translate(-10%,5%) }
  60% { transform: translate(15%,0) }
  70% { transform: translate(0,10%) }
  80% { transform: translate(-15%,0) }
  90% { transform: translate(10%,5%) }
  100% { transform: translate(5%,0) }
`

const NoiseBg = styled.div`
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: url(${noiseBg}) repeat 0 0;
  animation: ${NoiseAnimation} 0.2s steps(1) infinite;
  opacity: 0.09;
  pointer-events: none;
  z-index: 2;
`
