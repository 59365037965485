import React from 'react'
import styled from 'styled-components'
import { navList } from 'store/navigation'

interface Props {}

function Navigation(props: Props) {
  const {} = props

  return (
    <Root>
      {navList.map((item) => {
        return (
          <Link href={item.link} target="_blank">
            {item.name}
          </Link>
        )
      })}
      <Button disabled={true}>Get Started</Button>
    </Root>
  )
}

export default Navigation

const Root = styled.div`
  display: flex;
  align-items: center;
  font-size: 21px;
  line-height: 23px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 18px;
    line-height: 20px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 16px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
    line-height: 15px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const Link = styled.a`
  color: #000;
  margin-left: 75px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-left: 65px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-left: 56px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-left: 48px;
  }
  &:first-child {
    margin-left: 0;
  }
`

const Button = styled.button`
  display: block;
  position: relative;
  padding: 12px 60px;
  margin-left: 45px;
  color: #000;
  font-size: 21px;
  ${({ theme }) => theme.adaptive.xl} {
    padding: 12px 56px;
    margin-left: 45px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding: 10px 53px;
    margin-left: 40px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding: 9px 45px;
    margin-left: 35px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100%);
    border: 1px solid #515151;
    box-sizing: border-box;
    transform: skew(-45deg);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`
