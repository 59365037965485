import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import burgerImg from 'img/mobile/burger.svg'
import arrowImg from 'img/mobile/arrow.svg'
import useOnClickOutside from 'helpers/hooks/useOnClickOutside'
import { navList } from 'store/navigation'
interface Props {}

function MobNavigation(props: Props) {
  const {} = props
  const [isActive, setIsActive] = useState(false)
  const ref = useRef(null)

  useOnClickOutside({
    ref: ref,
    handler: () => setIsActive(false),
  })

  return (
    <Root>
      <Button disabled={true}>Get Started</Button>
      <Burger
        onClick={() => {
          setIsActive(true)
        }}
      />
      <MobNav ref={ref} active={isActive}>
        {navList.map((item) => {
          return (
            <MobLink href={item.link} target="_blank">
              {item.name}
            </MobLink>
          )
        })}
        <Close
          onClick={() => {
            setIsActive(false)
          }}
        />
      </MobNav>
    </Root>
  )
}

export default MobNavigation

interface panelProps {
  active: boolean
}

const Root = styled.div`
  display: none;
  ${({ theme }) => theme.adaptive.sm} {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    bottom: 236px;
    font-size: 21px;
    line-height: 24px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    bottom: 227px;
    font-size: 18px;
    line-height: 21px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    bottom: 103px;
    font-size: 16px;
    line-height: 19px;
  }
  &:before {
    ${({ theme }) => theme.adaptive.sm} {
      content: '';
      position: absolute;
      top: 0;
      background: #ddd9d8;
      transform: skew(-45deg);
      height: 41px;
      width: 215px;
      right: -50px;
    }
    ${({ theme }) => theme.adaptive.smm} {
      height: 35px;
      width: 167px;
      right: -63px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      height: 32px;
      width: 142px;
      right: -54px;
    }
  }
`

const Button = styled.button`
  ${({ theme }) => theme.adaptive.sm} {
    display: block;
    position: relative;
    padding: 13px 68px;
    margin-right: 51px;
    color: #000;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding: 10px 50px;
    margin-right: 39px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding: 8px 40px;
    margin-right: 32px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100%);
    border: 1px solid #515151;
    box-sizing: border-box;
    transform: skew(-45deg);
  }
  &:disabled {
    opacity: 0.4;
  }
`

const Burger = styled.div`
  position: relative;
  width: 60px;
  height: 20px;
  background: url('${burgerImg}') center no-repeat;
  background-size: cover;
  margin-right: 91px;
  z-index: 6;
  ${({ theme }) => theme.adaptive.smm} {
    width: 49px;
    height: 16px;
    margin-right: 46px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 42px;
    height: 14px;
    margin-right: 39px;
  }
`

const MobNav = styled.div<panelProps>`
  position: absolute;
  bottom: 0;
  right: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 293px;
  height: 121px;
  z-index: 7;
  transition: 0.4s;
  opacity: ${({ active }) => (active ? 1 : 0)};
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
  ${({ theme }) => theme.adaptive.xs} {
    width: 259px;
    height: 103px;
    right: -5px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #141414;
    transform: skew(-45deg);
  }
`

const MobLink = styled.a`
  display: block;
  font-size: 18px;
  color: #f8f4f3;
  outline: none;
  z-index: 7;
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 16px;
  }
  &:nth-child(1) {
    margin-top: 20px;
    margin-left: 70px;
    ${({ theme }) => theme.adaptive.xs} {
      margin-top: 16px;
      margin-left: 60px;
    }
  }
  &:nth-child(2) {
    margin-left: 35px;
    margin-bottom: 20px;
    ${({ theme }) => theme.adaptive.xs} {
      margin-left: 30px;
      margin-bottom: 16px;
    }
  }
`

const Close = styled.div`
  position: absolute;
  bottom: 15px;
  right: 70px;
  width: 23px;
  height: 12px;
  background: url('${arrowImg}') center no-repeat;
  background-size: cover;
  cursor: pointer;
  z-index: 7;
  ${({ theme }) => theme.adaptive.xs} {
    bottom: 15px;
    right: 60px;
    width: 19px;
    height: 10px;
  }
`
