const theme = {
  adaptive: {
    xl: '@media screen and (max-width: 1820px), screen and (max-height: 925px)',
    lg: '@media screen and (max-width: 1500px), screen and (max-height: 825px)',
    slg: '@media screen and (max-width: 1200px), screen and (max-height: 650px)',
    sm: '@media screen and (max-width: 940px)',
    smm: '@media screen and (max-width: 620px), screen and (max-width: 940px) and (max-height: 950px)',
    xs: '@media screen and (max-width: 420px), screen and (max-width: 620px) and (max-height: 800px)',
  },
}

export default theme
