import { createGlobalStyle } from 'styled-components'
import InterRegularWoff from './fonts/Inter/Inter-Regular.woff'
import InterRegularWoff2 from './fonts/Inter/Inter-Regular.woff2'
import MetropolisRegularWoff from './fonts/Metropolis/Metropolis-Regular.woff'
import MetropolisRegularWoff2 from './fonts/Metropolis/Metropolis-Regular.woff2'

// Inter
const Inter = {
  regular: {
    woff: InterRegularWoff,
    woff2: InterRegularWoff2,
  },
}

// Metropolis
const Metropolis = {
  regular: {
    woff: MetropolisRegularWoff,
    woff2: MetropolisRegularWoff2,
  },
}

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    src: local('Inter'),
      url(${Inter.regular.woff2}) format('woff2'),
      url(${Inter.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'),
      url(${Metropolis.regular.woff2}) format('woff2'),
      url(${Metropolis.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
 
`

export default Fonts
