import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Telegram } from 'img/socials/telegram.svg'
import { ReactComponent as Twitter } from 'img/socials/twitter.svg'
import { ReactComponent as Medium } from 'img/socials/medium.svg'

interface Props {}

function Socials(props: Props) {
  const {} = props

  return (
    <Root>
      <SocialBlock
        href={'https://twitter.com/Sunflower_Corp'}
        target={'_blank'}
      >
        <SocialIcon>
          <Twitter />
        </SocialIcon>
        <SocialText>TWITTER</SocialText>
      </SocialBlock>
      <SocialBlock
        href={'https://t.me/SunflowerCorporationCommunity'}
        target={'_blank'}
      >
        <SocialIcon>
          <Telegram />
        </SocialIcon>
        <SocialText>TELEGRAM</SocialText>
      </SocialBlock>
      <SocialBlock
        href={'https://medium.com/sunflowercorporation'}
        target={'_blank'}
      >
        <SocialIcon>
          <Medium />
        </SocialIcon>
        <SocialText>MEDIUM</SocialText>
      </SocialBlock>
    </Root>
  )
}

export default Socials

const Root = styled.div`
  position: absolute;
  right: 90px;
  bottom: 90px;
  ${({ theme }) => theme.adaptive.xl} {
    right: 82px;
    bottom: 82px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    right: 70px;
    bottom: 70px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    right: 54px;
    bottom: 54px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    right: 34px;
    bottom: 55px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 320px;
    bottom: 43px;
    left: 50%;
    right: unset;
    transform: translateX(-50%);
    justify-content: space-between;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 273px;
    bottom: 37px;
  }
`

const SocialText = styled.div`
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #141414;
  letter-spacing: -0.01px;
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 11px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 12px;
  }
`

const SocialIcon = styled.div`
  text-align: center;
  margin-bottom: 7px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-bottom: 8px;
  }
  svg {
    width: 18px;
    height: auto;
    ${({ theme }) => theme.adaptive.xl} {
      width: 16px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 13px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 15px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 21px;
    }
    ${({ theme }) => theme.adaptive.smm} {
      width: 16px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      width: 13px;
    }
  }
`

const SocialBlock = styled.a`
  position: relative;
  display: block;
  cursor: pointer;
  outline: none;
  margin-bottom: 40px;
  height: auto;
  ${({ theme }) => theme.adaptive.xl} {
    margin-bottom: 52px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-bottom: 45px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-bottom: 50px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-bottom: 0;
    margin-left: 75px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    ${SocialText} {
      /* color: #fb775b; */
    }
    ${SocialIcon} {
      /* svg {
        fill: #fb775b;
      } */
    }
  }
`
